.app-main .app-main__outer {
  width: 75% !important;
}

.OvrFlow {
  overflow: auto;
}

.OvrFlow::-webkit-scrollbar {
  width: 12px;
}

@media (max-width: 991.98px) {
  .app-main .app-main__outer {
    text-align: center;
    width: 100% !important;
  }
}

.app-main .app-main__inner {
  margin-left: 20px;
}

.table> :not(caption)>*>* {
  white-space: nowrap;
}

/*---------------------------------------*/
/* Absolute Center Spinner */
.loading_website {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading_website:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
      rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading_website:not(:required) {
  /* hide "loading_website..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading_website:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  /* background-image: url('../Assets/images/loader.gif');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 11; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hmm {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 400px;
  width: 100%;
}

/*---Web info page css---*/
.adEdittxt h1 {
  font-size: 19px;
  font-weight: 500;
}

.submitbtn {
  text-align: center;
  height: 100%;
  width: 10%;
}

.submitbtn button {
  border-radius: 10px;
  background-color: #0d6efd;
  padding: 9px 10px 9px 10px;
}

/*----syllabus page css----*/
.Addrrrr {
  margin-bottom: 20px;
}

.AddROwaddtxt {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.addRowbtn button,
.SaveBtttn,
.editBtn {
  font-family: "Poppins", sans-serif;
  color: black;
  border: 2px solid #518db0;
}

.addRowbtn button:focus,
.addRowbtn button:hover {
  color: black;
  background-color: transparent;
}

.SaveBtttn {
  width: 30%;
}

.actionmodes {
  width: 60%;
}

.editBtn {
  padding: 7px 0px 7px 0px;
}

/*------News Page-----*/
.SavBtn {
  padding: 7px 0px 7px 0px;
  color: black;
  border: 2px solid green;
}

.dltsvBtn {
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dltBtn,
.cencelBtnn {
  padding: 7px 0px 7px 0px;
  color: black;
  border: 2px solid red;
}

/*----program advisory page---*/
.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
  margin-top: 20px;
}

.addRowbtn button {
  background-color: transparent;
}

.addRowbtn button:hover {
  border: 2px solid green;
}

.pdfICNIMG {
  height: 27px;
  width: 25px;
}

.redBttt button {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 10px;
  font-weight: 500;
}

.description-container {
  max-height: 4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.redBttt {
  height: 100px;
  background-color: #f2f2f2;

  max-width: 100px !important;
  overflow-x: hidden !important;
  overflow: scroll !important;
}

.width10000 {
  width: 100px;
  height: 100px;

}